import TagManager from "react-gtm-module";
import { useRouter } from "next/router";
import { Interpolation, Theme, css } from "@emotion/react";
import styled from "@emotion/styled";
import { ExtensionLink } from "components/ExtensionLink";
import { ToggleButton } from "components/buttons/ToggleButton";
import {
  MIX_CONTENT_TAG_ENUM_KOREAN,
  ORDERED_MIX_CONTENT_TAG_ENUM,
} from "utils/enum";

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  button {
    width: 100%;
  }
`;

const SubMenuList = styled.div`
  flex-direction: column;
  gap: 4px;
  padding: 8px 0;
`;

const SubMenu = styled.div<{ focused: boolean }>`
  ${({ theme, focused }) => css`
    ${theme.typography.body3};
    font-weight: ${focused ? "700" : "400"};
    color: ${focused
      ? theme.palette.secondary.main
      : theme.palette.text.disabled};
    padding: 2px 0 2px 48px;
    cursor: pointer;

    &:hover {
      color: ${theme.palette.secondary.main};
    }
  `}
`;

export const NavMenu = ({
  showSubMenu,
  ...props
}: {
  showSubMenu: boolean;
  css?: Interpolation<Theme>;
}) => {
  const router = useRouter();

  return (
    <MenuList {...props}>
      <ExtensionLink href="/">
        <ToggleButton name="All" active={router.pathname === "/"}>
          모든 콘텐츠
        </ToggleButton>
      </ExtensionLink>
      {showSubMenu && (
        <SubMenuList
          css={css`
            display: flex;
            overflow: hidden;

            ${router.pathname === "/"
              ? css`
                  max-height: ${ORDERED_MIX_CONTENT_TAG_ENUM.length * 30}px;
                  transition: all 0.2s ease-in;
                `
              : css`
                  max-height: 0;
                  transition: all 0.2s ease-out;
                  padding: 0;
                `}
          `}
        >
          {ORDERED_MIX_CONTENT_TAG_ENUM.map((value) => (
            <ExtensionLink
              key={value}
              href={`/?tag=${value}`}
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "content_category_click",
                    value,
                  },
                });
              }}
            >
              <SubMenu key={value} focused={router.asPath.endsWith(value)}>
                {MIX_CONTENT_TAG_ENUM_KOREAN[value]}
              </SubMenu>
            </ExtensionLink>
          ))}
        </SubMenuList>
      )}
      <ExtensionLink href="/original">
        <ToggleButton name="Original" active={router.pathname === "/original"}>
          오리지널 콘텐츠
        </ToggleButton>
      </ExtensionLink>
      <ExtensionLink href="/bookmark">
        <ToggleButton
          name="BookmarkFilled"
          active={["/bookmark", "/history"].some((path) =>
            router.pathname.startsWith(path),
          )}
        >
          북마크
        </ToggleButton>
      </ExtensionLink>
      <a href="https://support.mix.day" target="_blank" rel="noreferrer">
        <ToggleButton name="InfoFilled" active={false}>
          고객센터
        </ToggleButton>
      </a>
    </MenuList>
  );
};

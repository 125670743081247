import { ComponentProps } from "react";
import Link from "next/link";

export function ExtensionLink({ href, ...props }: ComponentProps<typeof Link>) {
  if (process.env.BUILD_TARGET === "extension") {
    return <a href={`https://mix.day` + href} {...props} />;
  }

  return <Link href={href} {...props} />;
}

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** unique or primary key constraints on table "mix_admin" */
export enum Mix_Admin_Constraint {
  /** unique or primary key constraint on columns "email" */
  MixAdminEmailKey = 'mix_admin_email_key',
  /** unique or primary key constraint on columns "id" */
  MixAdminPkey = 'mix_admin_pkey'
}

/** select columns of table "mix_admin" */
export enum Mix_Admin_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password'
}

/** update columns of table "mix_admin" */
export enum Mix_Admin_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password'
}

/** unique or primary key constraints on table "mix_author" */
export enum Mix_Author_Constraint {
  /** unique or primary key constraint on columns "authorid", "source" */
  MixAuthorPkey = 'mix_author_pkey'
}

/** select columns of table "mix_author" */
export enum Mix_Author_Select_Column {
  /** column name */
  Authorid = 'authorid',
  /** column name */
  Authorname = 'authorname',
  /** column name */
  Source = 'source',
  /** column name */
  Tier = 'tier'
}

/** unique or primary key constraints on table "mix_author_tier" */
export enum Mix_Author_Tier_Constraint {
  /** unique or primary key constraint on columns "tier" */
  MixAuthorTierPkey = 'mix_author_tier_pkey'
}

/** select columns of table "mix_author_tier" */
export enum Mix_Author_Tier_Select_Column {
  /** column name */
  Tier = 'tier'
}

/** update columns of table "mix_author_tier" */
export enum Mix_Author_Tier_Update_Column {
  /** column name */
  Tier = 'tier'
}

/** update columns of table "mix_author" */
export enum Mix_Author_Update_Column {
  /** column name */
  Authorid = 'authorid',
  /** column name */
  Authorname = 'authorname',
  /** column name */
  Source = 'source',
  /** column name */
  Tier = 'tier'
}

/** unique or primary key constraints on table "mix_content_bookmark" */
export enum Mix_Content_Bookmark_Constraint {
  /** unique or primary key constraint on columns "id" */
  MixContentBookmarkPkey = 'mix_content_bookmark_pkey',
  /** unique or primary key constraint on columns "contentid", "userid" */
  MixContentBookmarkUseridContentidKey = 'mix_content_bookmark_userid_contentid_key'
}

/** select columns of table "mix_content_bookmark" */
export enum Mix_Content_Bookmark_Select_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Id = 'id',
  /** column name */
  Updatetime = 'updatetime',
  /** column name */
  Userid = 'userid'
}

/** update columns of table "mix_content_bookmark" */
export enum Mix_Content_Bookmark_Update_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Id = 'id',
  /** column name */
  Updatetime = 'updatetime',
  /** column name */
  Userid = 'userid'
}

/** unique or primary key constraints on table "mix_content_category" */
export enum Mix_Content_Category_Constraint {
  /** unique or primary key constraint on columns "value" */
  MixContentCategoryEnumPkey = 'mix_content_category_enum_pkey'
}

export enum Mix_Content_Category_Enum {
  /** 믹스 앰배서더 */
  Ambassador = 'Ambassador',
  /** 베스트 콘텐츠 */
  BestContents = 'Best_Contents',
  /** CMO 인터뷰 */
  Cmo = 'CMO',
  /** 이벤트 */
  Event = 'Event',
  /** 글로벌 인사이트 */
  GlobalInsight = 'Global_Insight',
  /** 오프라인 세션 */
  Offline = 'Offline',
  /** 리포트 발행 */
  Report = 'Report'
}

/** select columns of table "mix_content_category" */
export enum Mix_Content_Category_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** update columns of table "mix_content_category" */
export enum Mix_Content_Category_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** unique or primary key constraints on table "mix_content" */
export enum Mix_Content_Constraint {
  /** unique or primary key constraint on columns "author", "contentid" */
  MixContentAuthorContentidKey = 'mix_content_author_contentid_key',
  /** unique or primary key constraint on columns "default_product_id", "id" */
  MixContentIdDefaultProductIdKey = 'mix_content_id_default_product_id_key',
  /** unique or primary key constraint on columns "recommendorder" */
  MixContentRecommendedKey = 'mix_content_recommended_key',
  /** unique or primary key constraint on columns "source", "contentid" */
  MixContentSourceContentidKey = 'mix_content_source_contentid_key',
  /** unique or primary key constraint on columns "id" */
  MixContentTablePkey = 'mix_content_table_pkey'
}

/** unique or primary key constraints on table "mix_content_daily_selected" */
export enum Mix_Content_Daily_Selected_Constraint {
  /** unique or primary key constraint on columns "id" */
  MixContentDailySelectedPkey = 'mix_content_daily_selected_pkey'
}

/** select columns of table "mix_content_daily_selected" */
export enum Mix_Content_Daily_Selected_Select_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Id = 'id',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  Published = 'published',
  /** column name */
  Updatetime = 'updatetime'
}

/** update columns of table "mix_content_daily_selected" */
export enum Mix_Content_Daily_Selected_Update_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Id = 'id',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  Published = 'published',
  /** column name */
  Updatetime = 'updatetime'
}

/** unique or primary key constraints on table "mix_content_encore" */
export enum Mix_Content_Encore_Constraint {
  /** unique or primary key constraint on columns "contentid", "userid" */
  MixContentEncoreContentidUseridKey = 'mix_content_encore_contentid_userid_key',
  /** unique or primary key constraint on columns "id" */
  MixContentEncorePkey = 'mix_content_encore_pkey'
}

/** select columns of table "mix_content_encore" */
export enum Mix_Content_Encore_Select_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Count = 'count',
  /** column name */
  Id = 'id',
  /** column name */
  Userid = 'userid'
}

/** update columns of table "mix_content_encore" */
export enum Mix_Content_Encore_Update_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Count = 'count',
  /** column name */
  Id = 'id',
  /** column name */
  Userid = 'userid'
}

/** unique or primary key constraints on table "mix_content_history" */
export enum Mix_Content_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  MixContentHistoryPkey = 'mix_content_history_pkey',
  /** unique or primary key constraint on columns "contentid", "userid" */
  MixContentHistoryUseridContentidKey = 'mix_content_history_userid_contentid_key'
}

/** select columns of table "mix_content_history" */
export enum Mix_Content_History_Select_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Id = 'id',
  /** column name */
  Updatetime = 'updatetime',
  /** column name */
  Userid = 'userid'
}

/** update columns of table "mix_content_history" */
export enum Mix_Content_History_Update_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Id = 'id',
  /** column name */
  Updatetime = 'updatetime',
  /** column name */
  Userid = 'userid'
}

/** unique or primary key constraints on table "mix_content_recommend" */
export enum Mix_Content_Recommend_Constraint {
  /** unique or primary key constraint on columns "updatedate", "priority" */
  MixContentRecommendUpdatedatePriorityKey = 'mix_content_recommend_updatedate_priority_key',
  /** unique or primary key constraint on columns "id" */
  MixContentWeeklySelectedPkey = 'mix_content_weekly_selected_pkey'
}

/** select columns of table "mix_content_recommend" */
export enum Mix_Content_Recommend_Select_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Id = 'id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Published = 'published',
  /** column name */
  Updatedate = 'updatedate'
}

/** select "mix_content_recommend_aggregate_bool_exp_bool_and_arguments_columns" columns of table "mix_content_recommend" */
export enum Mix_Content_Recommend_Select_Column_Mix_Content_Recommend_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Published = 'published'
}

/** select "mix_content_recommend_aggregate_bool_exp_bool_or_arguments_columns" columns of table "mix_content_recommend" */
export enum Mix_Content_Recommend_Select_Column_Mix_Content_Recommend_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Published = 'published'
}

/** update columns of table "mix_content_recommend" */
export enum Mix_Content_Recommend_Update_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Id = 'id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Published = 'published',
  /** column name */
  Updatedate = 'updatedate'
}

/** select columns of table "mix_content" */
export enum Mix_Content_Select_Column {
  /** column name */
  Abstractcontents = 'abstractcontents',
  /** column name */
  Author = 'author',
  /** column name */
  Authorname = 'authorname',
  /** column name */
  Category = 'category',
  /** column name */
  Contentid = 'contentid',
  /** column name */
  DefaultProductId = 'default_product_id',
  /** column name */
  Id = 'id',
  /** column name */
  Imgurl = 'imgurl',
  /** column name */
  Offlineapplyurl = 'offlineapplyurl',
  /** column name */
  Offlinetime = 'offlinetime',
  /** column name */
  Originalurl = 'originalurl',
  /** column name */
  Pdfpagecount = 'pdfpagecount',
  /** column name */
  Pdfurl = 'pdfurl',
  /** column name */
  Price = 'price',
  /** column name */
  Readtime = 'readtime',
  /** column name */
  Recommendorder = 'recommendorder',
  /** column name */
  Redirecturl = 'redirecturl',
  /** column name */
  Reviewer = 'reviewer',
  /** column name */
  Reviewtime = 'reviewtime',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Updatetime = 'updatetime'
}

/** unique or primary key constraints on table "mix_content_status" */
export enum Mix_Content_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  MixContentStatusPkey = 'mix_content_status_pkey'
}

export enum Mix_Content_Status_Enum {
  Crawled = 'crawled',
  PartialPublished = 'partial_published',
  Published = 'published',
  Rejected = 'rejected',
  Scheduled = 'scheduled'
}

/** select columns of table "mix_content_status" */
export enum Mix_Content_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "mix_content_status" */
export enum Mix_Content_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** unique or primary key constraints on table "mix_content_tag" */
export enum Mix_Content_Tag_Constraint {
  /** unique or primary key constraint on columns "tag", "contentid" */
  MixContentTagPkey1 = 'mix_content_tag_pkey1'
}

/** unique or primary key constraints on table "mix_content_tag_enum" */
export enum Mix_Content_Tag_Enum_Constraint {
  /** unique or primary key constraint on columns "value" */
  MixContentTagPkey = 'mix_content_tag_pkey'
}

export enum Mix_Content_Tag_Enum_Enum {
  /** 제휴마케팅 */
  AffiliateMarketing = 'affiliate_marketing',
  /** 브랜드마케팅 */
  BrandMarketing = 'brand_marketing',
  /** 비즈니스 */
  Business = 'business',
  /** 커리어 */
  Career = 'career',
  /** 콘텐츠마케팅 */
  ContentMarketing = 'content_marketing',
  /** CRM마케팅 */
  CrmMarketing = 'crm_marketing',
  /** 그로스해킹 */
  GrowthHacking = 'growth_hacking',
  /** 마켓트렌드 */
  MarketTrend = 'market_trend',
  /** 일반마케팅 */
  NormalMarketing = 'normal_marketing',
  /** 퍼포먼스마케팅 */
  PerformanceMarketing = 'performance_marketing',
  /** SEO */
  Seo = 'seo'
}

/** select columns of table "mix_content_tag_enum" */
export enum Mix_Content_Tag_Enum_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** update columns of table "mix_content_tag_enum" */
export enum Mix_Content_Tag_Enum_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** select columns of table "mix_content_tag" */
export enum Mix_Content_Tag_Select_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Reviewer = 'reviewer',
  /** column name */
  Tag = 'tag'
}

/** update columns of table "mix_content_tag" */
export enum Mix_Content_Tag_Update_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Reviewer = 'reviewer',
  /** column name */
  Tag = 'tag'
}

/** update columns of table "mix_content" */
export enum Mix_Content_Update_Column {
  /** column name */
  Abstractcontents = 'abstractcontents',
  /** column name */
  Author = 'author',
  /** column name */
  Authorname = 'authorname',
  /** column name */
  Category = 'category',
  /** column name */
  Contentid = 'contentid',
  /** column name */
  DefaultProductId = 'default_product_id',
  /** column name */
  Id = 'id',
  /** column name */
  Imgurl = 'imgurl',
  /** column name */
  Offlineapplyurl = 'offlineapplyurl',
  /** column name */
  Offlinetime = 'offlinetime',
  /** column name */
  Originalurl = 'originalurl',
  /** column name */
  Pdfpagecount = 'pdfpagecount',
  /** column name */
  Pdfurl = 'pdfurl',
  /** column name */
  Price = 'price',
  /** column name */
  Readtime = 'readtime',
  /** column name */
  Recommendorder = 'recommendorder',
  /** column name */
  Redirecturl = 'redirecturl',
  /** column name */
  Reviewer = 'reviewer',
  /** column name */
  Reviewtime = 'reviewtime',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Updatetime = 'updatetime'
}

/** unique or primary key constraints on table "mix_content_view" */
export enum Mix_Content_View_Constraint {
  /** unique or primary key constraint on columns "id" */
  MixContentViewPkey = 'mix_content_view_pkey'
}

/** select columns of table "mix_content_view" */
export enum Mix_Content_View_Select_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Createtime = 'createtime',
  /** column name */
  Id = 'id',
  /** column name */
  Userid = 'userid'
}

/** update columns of table "mix_content_view" */
export enum Mix_Content_View_Update_Column {
  /** column name */
  Contentid = 'contentid',
  /** column name */
  Createtime = 'createtime',
  /** column name */
  Id = 'id',
  /** column name */
  Userid = 'userid'
}

/** unique or primary key constraints on table "mix_crawler_daily_result" */
export enum Mix_Crawler_Daily_Result_Constraint {
  /** unique or primary key constraint on columns "updatedate", "source" */
  MixCrawlerDailyResultPkey = 'mix_crawler_daily_result_pkey'
}

/** select columns of table "mix_crawler_daily_result" */
export enum Mix_Crawler_Daily_Result_Select_Column {
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  Updatedate = 'updatedate'
}

/** unique or primary key constraints on table "mix_crawler_daily_result_status" */
export enum Mix_Crawler_Daily_Result_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  MixCrawlerResultStatusPkey = 'mix_crawler_result_status_pkey'
}

export enum Mix_Crawler_Daily_Result_Status_Enum {
  /** 실패 */
  Failed = 'FAILED',
  /** 성공 */
  Success = 'SUCCESS'
}

/** select columns of table "mix_crawler_daily_result_status" */
export enum Mix_Crawler_Daily_Result_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** update columns of table "mix_crawler_daily_result_status" */
export enum Mix_Crawler_Daily_Result_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** update columns of table "mix_crawler_daily_result" */
export enum Mix_Crawler_Daily_Result_Update_Column {
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  Updatedate = 'updatedate'
}

/** unique or primary key constraints on table "mix_last_content" */
export enum Mix_Last_Content_Constraint {
  /** unique or primary key constraint on columns "author", "source" */
  MixLastContentPkey = 'mix_last_content_pkey'
}

/** select columns of table "mix_last_content" */
export enum Mix_Last_Content_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  Originalurl = 'originalurl',
  /** column name */
  Source = 'source',
  /** column name */
  Updatetime = 'updatetime'
}

/** update columns of table "mix_last_content" */
export enum Mix_Last_Content_Update_Column {
  /** column name */
  Author = 'author',
  /** column name */
  Originalurl = 'originalurl',
  /** column name */
  Source = 'source',
  /** column name */
  Updatetime = 'updatetime'
}

/** unique or primary key constraints on table "mix_source_info" */
export enum Mix_Source_Info_Constraint {
  /** unique or primary key constraint on columns "source" */
  MixSourceEnumPkey = 'mix_source_enum_pkey'
}

/** select columns of table "mix_source_info" */
export enum Mix_Source_Info_Select_Column {
  /** column name */
  Createdby = 'createdby',
  /** column name */
  Imgurl = 'imgurl',
  /** column name */
  Source = 'source',
  /** column name */
  Sourcename = 'sourcename'
}

/** update columns of table "mix_source_info" */
export enum Mix_Source_Info_Update_Column {
  /** column name */
  Createdby = 'createdby',
  /** column name */
  Imgurl = 'imgurl',
  /** column name */
  Source = 'source',
  /** column name */
  Sourcename = 'sourcename'
}

/** unique or primary key constraints on table "mix_user" */
export enum Mix_User_Constraint {
  /** unique or primary key constraint on columns "method", "email" */
  MixUserEmailMethodKey = 'mix_user_email_method_key',
  /** unique or primary key constraint on columns "id" */
  MixUserPkey = 'mix_user_pkey'
}

/** select columns of table "mix_user" */
export enum Mix_User_Select_Column {
  /** column name */
  Career = 'career',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  ProfileImg = 'profileImg',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  RefreshTokenExpiry = 'refreshTokenExpiry',
  /** column name */
  SubscribeEmail = 'subscribeEmail'
}

/** update columns of table "mix_user" */
export enum Mix_User_Update_Column {
  /** column name */
  Career = 'career',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  ProfileImg = 'profileImg',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  RefreshTokenExpiry = 'refreshTokenExpiry',
  /** column name */
  SubscribeEmail = 'subscribeEmail'
}

/** select columns of table "mix_user_view" */
export enum Mix_User_View_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastContentViewTime = 'last_content_view_time',
  /** column name */
  SumContentView = 'sum_content_view',
  /** column name */
  VisitCount = 'visit_count'
}

/** unique or primary key constraints on table "offline_attendee" */
export enum Offline_Attendee_Constraint {
  /** unique or primary key constraint on columns "phone", "order_id" */
  OfflineAttendeeOrderIdPhoneKey = 'offline_attendee_order_id_phone_key',
  /** unique or primary key constraint on columns "id" */
  OfflineAttendeePkey = 'offline_attendee_pkey'
}

/** select columns of table "offline_attendee_enrolled_view" */
export enum Offline_Attendee_Enrolled_View_Select_Column {
  /** column name */
  AdvanceQuestion = 'advance_question',
  /** column name */
  Career = 'career',
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderName = 'order_name',
  /** column name */
  OrderStatus = 'order_status',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position'
}

/** select columns of table "offline_attendee" */
export enum Offline_Attendee_Select_Column {
  /** column name */
  AdvanceQuestion = 'advance_question',
  /** column name */
  Career = 'career',
  /** column name */
  Company = 'company',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InterestCategory = 'interest_category',
  /** column name */
  IsVipNetworkingEnrolled = 'is_vip_networking_enrolled',
  /** column name */
  Name = 'name',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  TeamAndRole = 'team_and_role'
}

/** select "offline_attendee_aggregate_bool_exp_bool_and_arguments_columns" columns of table "offline_attendee" */
export enum Offline_Attendee_Select_Column_Offline_Attendee_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsVipNetworkingEnrolled = 'is_vip_networking_enrolled'
}

/** select "offline_attendee_aggregate_bool_exp_bool_or_arguments_columns" columns of table "offline_attendee" */
export enum Offline_Attendee_Select_Column_Offline_Attendee_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsVipNetworkingEnrolled = 'is_vip_networking_enrolled'
}

/** update columns of table "offline_attendee" */
export enum Offline_Attendee_Update_Column {
  /** column name */
  AdvanceQuestion = 'advance_question',
  /** column name */
  Career = 'career',
  /** column name */
  Company = 'company',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InterestCategory = 'interest_category',
  /** column name */
  IsVipNetworkingEnrolled = 'is_vip_networking_enrolled',
  /** column name */
  Name = 'name',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  TeamAndRole = 'team_and_role'
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** unique or primary key constraints on table "order" */
export enum Order_Constraint {
  /** unique or primary key constraint on columns "order_token" */
  OrderOrderTokenKey = 'order_order_token_key',
  /** unique or primary key constraint on columns "id" */
  OrderPkey = 'order_pkey'
}

/** select columns of table "order" */
export enum Order_Select_Column {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  DiscountApply = 'discount_apply',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  Name = 'name',
  /** column name */
  OrderToken = 'order_token',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RequestedAt = 'requested_at',
  /** column name */
  Status = 'status',
  /** column name */
  TotalAmount = 'total_amount'
}

/** unique or primary key constraints on table "order_status" */
export enum Order_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  OrderStatusPkey = 'order_status_pkey'
}

export enum Order_Status_Enum {
  /** 승인 실패 */
  Aborted = 'ABORTED',
  /** 취소 완료 */
  Canceled = 'CANCELED',
  /** 결제 완료 */
  Done = 'DONE',
  /** 시간 만료 */
  Expired = 'EXPIRED',
  /** 승인 대기 */
  InProgress = 'IN_PROGRESS',
  /** 부분 취소 */
  PartialCanceled = 'PARTIAL_CANCELED',
  /** 미결제 */
  Ready = 'READY',
  /** 취소 대기 */
  WaitingForCanceled = 'WAITING_FOR_CANCELED',
  /** 입금 대기 */
  WaitingForDeposit = 'WAITING_FOR_DEPOSIT'
}

/** select columns of table "order_status" */
export enum Order_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** update columns of table "order_status" */
export enum Order_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** update columns of table "order" */
export enum Order_Update_Column {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  DiscountApply = 'discount_apply',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  Name = 'name',
  /** column name */
  OrderToken = 'order_token',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RequestedAt = 'requested_at',
  /** column name */
  Status = 'status',
  /** column name */
  TotalAmount = 'total_amount'
}

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductPkey = 'product_pkey'
}

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DiscountEndTime = 'discount_end_time',
  /** column name */
  DiscountPrice = 'discount_price',
  /** column name */
  DiscountStartTime = 'discount_start_time',
  /** column name */
  DiscountStock = 'discount_stock',
  /** column name */
  Id = 'id',
  /** column name */
  IsGuestPaymentAllowed = 'is_guest_payment_allowed',
  /** column name */
  OrderEndTime = 'order_end_time',
  /** column name */
  Price = 'price',
  /** column name */
  Stock = 'stock'
}

/** select "product_aggregate_bool_exp_avg_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  DiscountPrice = 'discount_price',
  /** column name */
  Price = 'price'
}

/** select "product_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsGuestPaymentAllowed = 'is_guest_payment_allowed'
}

/** select "product_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsGuestPaymentAllowed = 'is_guest_payment_allowed'
}

/** select "product_aggregate_bool_exp_corr_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  DiscountPrice = 'discount_price',
  /** column name */
  Price = 'price'
}

/** select "product_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  DiscountPrice = 'discount_price',
  /** column name */
  Price = 'price'
}

/** select "product_aggregate_bool_exp_max_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  DiscountPrice = 'discount_price',
  /** column name */
  Price = 'price'
}

/** select "product_aggregate_bool_exp_min_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  DiscountPrice = 'discount_price',
  /** column name */
  Price = 'price'
}

/** select "product_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  DiscountPrice = 'discount_price',
  /** column name */
  Price = 'price'
}

/** select "product_aggregate_bool_exp_sum_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  DiscountPrice = 'discount_price',
  /** column name */
  Price = 'price'
}

/** select "product_aggregate_bool_exp_var_samp_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  DiscountPrice = 'discount_price',
  /** column name */
  Price = 'price'
}

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DiscountEndTime = 'discount_end_time',
  /** column name */
  DiscountPrice = 'discount_price',
  /** column name */
  DiscountStartTime = 'discount_start_time',
  /** column name */
  DiscountStock = 'discount_stock',
  /** column name */
  Id = 'id',
  /** column name */
  IsGuestPaymentAllowed = 'is_guest_payment_allowed',
  /** column name */
  OrderEndTime = 'order_end_time',
  /** column name */
  Price = 'price',
  /** column name */
  Stock = 'stock'
}

/** unique or primary key constraints on table "toss_payments_org_code" */
export enum Toss_Payments_Org_Code_Constraint {
  /** unique or primary key constraint on columns "code_num", "code_eng" */
  TossPaymentsOrgCodeCodeNumCodeEngKey = 'toss_payments_org_code_code_num_code_eng_key',
  /** unique or primary key constraint on columns "id" */
  TossPaymentsOrgCodePkey = 'toss_payments_org_code_pkey'
}

/** select columns of table "toss_payments_org_code" */
export enum Toss_Payments_Org_Code_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CodeEng = 'code_eng',
  /** column name */
  CodeKor = 'code_kor',
  /** column name */
  CodeNum = 'code_num',
  /** column name */
  Id = 'id',
  /** column name */
  Organization = 'organization',
  /** column name */
  TossUpdateDate = 'toss_update_date'
}

/** update columns of table "toss_payments_org_code" */
export enum Toss_Payments_Org_Code_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CodeEng = 'code_eng',
  /** column name */
  CodeKor = 'code_kor',
  /** column name */
  CodeNum = 'code_num',
  /** column name */
  Id = 'id',
  /** column name */
  Organization = 'organization',
  /** column name */
  TossUpdateDate = 'toss_update_date'
}

export const ProductFragmentDoc = gql`
    fragment product on product {
  id
  content_id
  price
  discount_price
  discount_start_time
  discount_end_time
  discount_amount
  created_at
  order_end_time
  is_default
  is_guest_payment_allowed
  on_order
  on_discount
  mix_content {
    id
    title
    abstractcontents
    category
    authorname
    imgurl
    updatetime
    offlinetime
    readtime
    default_product_id
  }
}
    `;
export const Mix_ContentFragmentDoc = gql`
    fragment mix_content on mix_content {
  id
  authorname
  abstractcontents
  author
  contentid
  imgurl
  originalurl
  redirecturl
  source
  title
  category
  readtime
  updatetime
  updatedate
  recommendorder
  pdfurl
  pdfpagecount
  offlinetime
  default_product_id
  bookmarked
  isoriginal
  isrecommend
  released
  mix_source_info {
    imgurl
  }
  mix_content_tags {
    mix_content_tag_enum {
      value
      comment
    }
  }
  products {
    ...product
  }
}
    ${ProductFragmentDoc}`;
export const Mix_UserFragmentDoc = gql`
    fragment mix_user on mix_user {
  id
  email
  name
  profileImg
  position
  career
  createdAt
  subscribeEmail
  method
}
    `;
export const Offline_AttendeeFragmentDoc = gql`
    fragment offline_attendee on offline_attendee {
  id
  order_id
  email
  name
  phone
  company
  position
  career
  advance_question
  team_and_role
  interest_category
  is_vip_networking_enrolled
}
    `;
export const OrderFragmentDoc = gql`
    fragment order on order {
  id
  customer_id
  customer_name
  customer_email
  product_id
  name
  total_amount
  method
  status
  created_at
  requested_at
  approved_at
  quantity
  discount_apply
  order_token
  product {
    ...product
  }
  offline_attendees {
    ...offline_attendee
  }
}
    ${ProductFragmentDoc}
${Offline_AttendeeFragmentDoc}`;
export const BookmarkDocument = gql`
    query bookmark($userId: uuid!, $limit: Int, $offset: Int) {
  mix_content_bookmark(
    where: {userid: {_eq: $userId}}
    limit: $limit
    offset: $offset
    order_by: {updatetime: desc}
  ) {
    id
    mix_content {
      ...mix_content
    }
  }
}
    ${Mix_ContentFragmentDoc}`;
export type BookmarkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BookmarkQuery, BookmarkQueryVariables>, 'query'> & ({ variables: BookmarkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BookmarkComponent = (props: BookmarkComponentProps) => (
      <ApolloReactComponents.Query<BookmarkQuery, BookmarkQueryVariables> query={BookmarkDocument} {...props} />
    );
    

/**
 * __useBookmarkQuery__
 *
 * To run a query within a React component, call `useBookmarkQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarkQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useBookmarkQuery(baseOptions: Apollo.QueryHookOptions<BookmarkQuery, BookmarkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookmarkQuery, BookmarkQueryVariables>(BookmarkDocument, options);
      }
export function useBookmarkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookmarkQuery, BookmarkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookmarkQuery, BookmarkQueryVariables>(BookmarkDocument, options);
        }
export type BookmarkQueryHookResult = ReturnType<typeof useBookmarkQuery>;
export type BookmarkLazyQueryHookResult = ReturnType<typeof useBookmarkLazyQuery>;
export type BookmarkQueryResult = Apollo.QueryResult<BookmarkQuery, BookmarkQueryVariables>;
export const DeleteBookmarkDocument = gql`
    mutation deleteBookmark($contentId: uuid!) {
  delete_mix_content_bookmark(where: {contentid: {_eq: $contentId}}) {
    returning {
      id
      mix_content {
        id
        bookmarked
      }
    }
  }
}
    `;
export type DeleteBookmarkMutationFn = Apollo.MutationFunction<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>;
export type DeleteBookmarkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>, 'mutation'>;

    export const DeleteBookmarkComponent = (props: DeleteBookmarkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteBookmarkMutation, DeleteBookmarkMutationVariables> mutation={DeleteBookmarkDocument} {...props} />
    );
    

/**
 * __useDeleteBookmarkMutation__
 *
 * To run a mutation, you first call `useDeleteBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookmarkMutation, { data, loading, error }] = useDeleteBookmarkMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useDeleteBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>(DeleteBookmarkDocument, options);
      }
export type DeleteBookmarkMutationHookResult = ReturnType<typeof useDeleteBookmarkMutation>;
export type DeleteBookmarkMutationResult = Apollo.MutationResult<DeleteBookmarkMutation>;
export type DeleteBookmarkMutationOptions = Apollo.BaseMutationOptions<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>;
export const DeleteOrderDocument = gql`
    mutation deleteOrder($where: order_bool_exp!) {
  delete_order(where: $where) {
    returning {
      id
    }
  }
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;
export type DeleteOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteOrderMutation, DeleteOrderMutationVariables>, 'mutation'>;

    export const DeleteOrderComponent = (props: DeleteOrderComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteOrderMutation, DeleteOrderMutationVariables> mutation={DeleteOrderDocument} {...props} />
    );
    

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;
export const HistoryDocument = gql`
    query history($userId: uuid!, $offset: Int, $limit: Int) {
  mix_content_history(
    where: {userid: {_eq: $userId}}
    offset: $offset
    limit: $limit
    order_by: {updatetime: desc}
  ) {
    id
    mix_content {
      ...mix_content
    }
  }
}
    ${Mix_ContentFragmentDoc}`;
export type HistoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HistoryQuery, HistoryQueryVariables>, 'query'> & ({ variables: HistoryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HistoryComponent = (props: HistoryComponentProps) => (
      <ApolloReactComponents.Query<HistoryQuery, HistoryQueryVariables> query={HistoryDocument} {...props} />
    );
    

/**
 * __useHistoryQuery__
 *
 * To run a query within a React component, call `useHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useHistoryQuery(baseOptions: Apollo.QueryHookOptions<HistoryQuery, HistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoryQuery, HistoryQueryVariables>(HistoryDocument, options);
      }
export function useHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoryQuery, HistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoryQuery, HistoryQueryVariables>(HistoryDocument, options);
        }
export type HistoryQueryHookResult = ReturnType<typeof useHistoryQuery>;
export type HistoryLazyQueryHookResult = ReturnType<typeof useHistoryLazyQuery>;
export type HistoryQueryResult = Apollo.QueryResult<HistoryQuery, HistoryQueryVariables>;
export const InsertBookmarkDocument = gql`
    mutation insertBookmark($contentId: uuid!) {
  insert_mix_content_bookmark_one(object: {contentid: $contentId}) {
    id
    mix_content {
      id
      bookmarked
    }
  }
}
    `;
export type InsertBookmarkMutationFn = Apollo.MutationFunction<InsertBookmarkMutation, InsertBookmarkMutationVariables>;
export type InsertBookmarkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertBookmarkMutation, InsertBookmarkMutationVariables>, 'mutation'>;

    export const InsertBookmarkComponent = (props: InsertBookmarkComponentProps) => (
      <ApolloReactComponents.Mutation<InsertBookmarkMutation, InsertBookmarkMutationVariables> mutation={InsertBookmarkDocument} {...props} />
    );
    

/**
 * __useInsertBookmarkMutation__
 *
 * To run a mutation, you first call `useInsertBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBookmarkMutation, { data, loading, error }] = useInsertBookmarkMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useInsertBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<InsertBookmarkMutation, InsertBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertBookmarkMutation, InsertBookmarkMutationVariables>(InsertBookmarkDocument, options);
      }
export type InsertBookmarkMutationHookResult = ReturnType<typeof useInsertBookmarkMutation>;
export type InsertBookmarkMutationResult = Apollo.MutationResult<InsertBookmarkMutation>;
export type InsertBookmarkMutationOptions = Apollo.BaseMutationOptions<InsertBookmarkMutation, InsertBookmarkMutationVariables>;
export const InsertMixContentViewDocument = gql`
    mutation insertMixContentView($object: mix_content_view_insert_input!) {
  insert_mix_content_view_one(object: $object) {
    id
  }
}
    `;
export type InsertMixContentViewMutationFn = Apollo.MutationFunction<InsertMixContentViewMutation, InsertMixContentViewMutationVariables>;
export type InsertMixContentViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertMixContentViewMutation, InsertMixContentViewMutationVariables>, 'mutation'>;

    export const InsertMixContentViewComponent = (props: InsertMixContentViewComponentProps) => (
      <ApolloReactComponents.Mutation<InsertMixContentViewMutation, InsertMixContentViewMutationVariables> mutation={InsertMixContentViewDocument} {...props} />
    );
    

/**
 * __useInsertMixContentViewMutation__
 *
 * To run a mutation, you first call `useInsertMixContentViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMixContentViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMixContentViewMutation, { data, loading, error }] = useInsertMixContentViewMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertMixContentViewMutation(baseOptions?: Apollo.MutationHookOptions<InsertMixContentViewMutation, InsertMixContentViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertMixContentViewMutation, InsertMixContentViewMutationVariables>(InsertMixContentViewDocument, options);
      }
export type InsertMixContentViewMutationHookResult = ReturnType<typeof useInsertMixContentViewMutation>;
export type InsertMixContentViewMutationResult = Apollo.MutationResult<InsertMixContentViewMutation>;
export type InsertMixContentViewMutationOptions = Apollo.BaseMutationOptions<InsertMixContentViewMutation, InsertMixContentViewMutationVariables>;
export const InsertOrderOneDocument = gql`
    mutation insertOrderOne($object: order_insert_input!) {
  insert_order_one(object: $object) {
    id
    order_token
  }
}
    `;
export type InsertOrderOneMutationFn = Apollo.MutationFunction<InsertOrderOneMutation, InsertOrderOneMutationVariables>;
export type InsertOrderOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertOrderOneMutation, InsertOrderOneMutationVariables>, 'mutation'>;

    export const InsertOrderOneComponent = (props: InsertOrderOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertOrderOneMutation, InsertOrderOneMutationVariables> mutation={InsertOrderOneDocument} {...props} />
    );
    

/**
 * __useInsertOrderOneMutation__
 *
 * To run a mutation, you first call `useInsertOrderOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOrderOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOrderOneMutation, { data, loading, error }] = useInsertOrderOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertOrderOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertOrderOneMutation, InsertOrderOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertOrderOneMutation, InsertOrderOneMutationVariables>(InsertOrderOneDocument, options);
      }
export type InsertOrderOneMutationHookResult = ReturnType<typeof useInsertOrderOneMutation>;
export type InsertOrderOneMutationResult = Apollo.MutationResult<InsertOrderOneMutation>;
export type InsertOrderOneMutationOptions = Apollo.BaseMutationOptions<InsertOrderOneMutation, InsertOrderOneMutationVariables>;
export const InsertUserDocument = gql`
    mutation insertUser($user: mix_user_insert_input!) {
  insert_mix_user_one(object: $user) {
    ...mix_user
  }
}
    ${Mix_UserFragmentDoc}`;
export type InsertUserMutationFn = Apollo.MutationFunction<InsertUserMutation, InsertUserMutationVariables>;
export type InsertUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertUserMutation, InsertUserMutationVariables>, 'mutation'>;

    export const InsertUserComponent = (props: InsertUserComponentProps) => (
      <ApolloReactComponents.Mutation<InsertUserMutation, InsertUserMutationVariables> mutation={InsertUserDocument} {...props} />
    );
    

/**
 * __useInsertUserMutation__
 *
 * To run a mutation, you first call `useInsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserMutation, { data, loading, error }] = useInsertUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useInsertUserMutation(baseOptions?: Apollo.MutationHookOptions<InsertUserMutation, InsertUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUserMutation, InsertUserMutationVariables>(InsertUserDocument, options);
      }
export type InsertUserMutationHookResult = ReturnType<typeof useInsertUserMutation>;
export type InsertUserMutationResult = Apollo.MutationResult<InsertUserMutation>;
export type InsertUserMutationOptions = Apollo.BaseMutationOptions<InsertUserMutation, InsertUserMutationVariables>;
export const MixContentDocument = gql`
    query mixContent($where: mix_content_bool_exp, $limit: Int, $offset: Int, $orderBy: [mix_content_order_by!]) {
  mix_content(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...mix_content
  }
}
    ${Mix_ContentFragmentDoc}`;
export type MixContentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MixContentQuery, MixContentQueryVariables>, 'query'>;

    export const MixContentComponent = (props: MixContentComponentProps) => (
      <ApolloReactComponents.Query<MixContentQuery, MixContentQueryVariables> query={MixContentDocument} {...props} />
    );
    

/**
 * __useMixContentQuery__
 *
 * To run a query within a React component, call `useMixContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixContentQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useMixContentQuery(baseOptions?: Apollo.QueryHookOptions<MixContentQuery, MixContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MixContentQuery, MixContentQueryVariables>(MixContentDocument, options);
      }
export function useMixContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixContentQuery, MixContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MixContentQuery, MixContentQueryVariables>(MixContentDocument, options);
        }
export type MixContentQueryHookResult = ReturnType<typeof useMixContentQuery>;
export type MixContentLazyQueryHookResult = ReturnType<typeof useMixContentLazyQuery>;
export type MixContentQueryResult = Apollo.QueryResult<MixContentQuery, MixContentQueryVariables>;
export const MixContentByPkDocument = gql`
    query mixContentByPk($id: uuid!) {
  mix_content_by_pk(id: $id) {
    ...mix_content
  }
}
    ${Mix_ContentFragmentDoc}`;
export type MixContentByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MixContentByPkQuery, MixContentByPkQueryVariables>, 'query'> & ({ variables: MixContentByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MixContentByPkComponent = (props: MixContentByPkComponentProps) => (
      <ApolloReactComponents.Query<MixContentByPkQuery, MixContentByPkQueryVariables> query={MixContentByPkDocument} {...props} />
    );
    

/**
 * __useMixContentByPkQuery__
 *
 * To run a query within a React component, call `useMixContentByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixContentByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixContentByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMixContentByPkQuery(baseOptions: Apollo.QueryHookOptions<MixContentByPkQuery, MixContentByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MixContentByPkQuery, MixContentByPkQueryVariables>(MixContentByPkDocument, options);
      }
export function useMixContentByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixContentByPkQuery, MixContentByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MixContentByPkQuery, MixContentByPkQueryVariables>(MixContentByPkDocument, options);
        }
export type MixContentByPkQueryHookResult = ReturnType<typeof useMixContentByPkQuery>;
export type MixContentByPkLazyQueryHookResult = ReturnType<typeof useMixContentByPkLazyQuery>;
export type MixContentByPkQueryResult = Apollo.QueryResult<MixContentByPkQuery, MixContentByPkQueryVariables>;
export const MixContentTagsDocument = gql`
    query mixContentTags {
  mix_content_tag_enum {
    value
    comment
  }
}
    `;
export type MixContentTagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MixContentTagsQuery, MixContentTagsQueryVariables>, 'query'>;

    export const MixContentTagsComponent = (props: MixContentTagsComponentProps) => (
      <ApolloReactComponents.Query<MixContentTagsQuery, MixContentTagsQueryVariables> query={MixContentTagsDocument} {...props} />
    );
    

/**
 * __useMixContentTagsQuery__
 *
 * To run a query within a React component, call `useMixContentTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixContentTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixContentTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMixContentTagsQuery(baseOptions?: Apollo.QueryHookOptions<MixContentTagsQuery, MixContentTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MixContentTagsQuery, MixContentTagsQueryVariables>(MixContentTagsDocument, options);
      }
export function useMixContentTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixContentTagsQuery, MixContentTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MixContentTagsQuery, MixContentTagsQueryVariables>(MixContentTagsDocument, options);
        }
export type MixContentTagsQueryHookResult = ReturnType<typeof useMixContentTagsQuery>;
export type MixContentTagsLazyQueryHookResult = ReturnType<typeof useMixContentTagsLazyQuery>;
export type MixContentTagsQueryResult = Apollo.QueryResult<MixContentTagsQuery, MixContentTagsQueryVariables>;
export const MixOriginalContentsDocument = gql`
    query mixOriginalContents {
  mix_content(where: {source: {_eq: "original"}}) {
    ...mix_content
  }
}
    ${Mix_ContentFragmentDoc}`;
export type MixOriginalContentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MixOriginalContentsQuery, MixOriginalContentsQueryVariables>, 'query'>;

    export const MixOriginalContentsComponent = (props: MixOriginalContentsComponentProps) => (
      <ApolloReactComponents.Query<MixOriginalContentsQuery, MixOriginalContentsQueryVariables> query={MixOriginalContentsDocument} {...props} />
    );
    

/**
 * __useMixOriginalContentsQuery__
 *
 * To run a query within a React component, call `useMixOriginalContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixOriginalContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixOriginalContentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMixOriginalContentsQuery(baseOptions?: Apollo.QueryHookOptions<MixOriginalContentsQuery, MixOriginalContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MixOriginalContentsQuery, MixOriginalContentsQueryVariables>(MixOriginalContentsDocument, options);
      }
export function useMixOriginalContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixOriginalContentsQuery, MixOriginalContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MixOriginalContentsQuery, MixOriginalContentsQueryVariables>(MixOriginalContentsDocument, options);
        }
export type MixOriginalContentsQueryHookResult = ReturnType<typeof useMixOriginalContentsQuery>;
export type MixOriginalContentsLazyQueryHookResult = ReturnType<typeof useMixOriginalContentsLazyQuery>;
export type MixOriginalContentsQueryResult = Apollo.QueryResult<MixOriginalContentsQuery, MixOriginalContentsQueryVariables>;
export const MixSourceInfoDocument = gql`
    query mixSourceInfo {
  mix_source_info(where: {imgurl: {_is_null: false}}) {
    source
    imgurl
  }
}
    `;
export type MixSourceInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MixSourceInfoQuery, MixSourceInfoQueryVariables>, 'query'>;

    export const MixSourceInfoComponent = (props: MixSourceInfoComponentProps) => (
      <ApolloReactComponents.Query<MixSourceInfoQuery, MixSourceInfoQueryVariables> query={MixSourceInfoDocument} {...props} />
    );
    

/**
 * __useMixSourceInfoQuery__
 *
 * To run a query within a React component, call `useMixSourceInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixSourceInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixSourceInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMixSourceInfoQuery(baseOptions?: Apollo.QueryHookOptions<MixSourceInfoQuery, MixSourceInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MixSourceInfoQuery, MixSourceInfoQueryVariables>(MixSourceInfoDocument, options);
      }
export function useMixSourceInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixSourceInfoQuery, MixSourceInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MixSourceInfoQuery, MixSourceInfoQueryVariables>(MixSourceInfoDocument, options);
        }
export type MixSourceInfoQueryHookResult = ReturnType<typeof useMixSourceInfoQuery>;
export type MixSourceInfoLazyQueryHookResult = ReturnType<typeof useMixSourceInfoLazyQuery>;
export type MixSourceInfoQueryResult = Apollo.QueryResult<MixSourceInfoQuery, MixSourceInfoQueryVariables>;
export const MixUserDocument = gql`
    query mixUser($where: mix_user_bool_exp) {
  mix_user(where: $where) {
    ...mix_user
  }
}
    ${Mix_UserFragmentDoc}`;
export type MixUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MixUserQuery, MixUserQueryVariables>, 'query'>;

    export const MixUserComponent = (props: MixUserComponentProps) => (
      <ApolloReactComponents.Query<MixUserQuery, MixUserQueryVariables> query={MixUserDocument} {...props} />
    );
    

/**
 * __useMixUserQuery__
 *
 * To run a query within a React component, call `useMixUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMixUserQuery(baseOptions?: Apollo.QueryHookOptions<MixUserQuery, MixUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MixUserQuery, MixUserQueryVariables>(MixUserDocument, options);
      }
export function useMixUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixUserQuery, MixUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MixUserQuery, MixUserQueryVariables>(MixUserDocument, options);
        }
export type MixUserQueryHookResult = ReturnType<typeof useMixUserQuery>;
export type MixUserLazyQueryHookResult = ReturnType<typeof useMixUserLazyQuery>;
export type MixUserQueryResult = Apollo.QueryResult<MixUserQuery, MixUserQueryVariables>;
export const MixUserByPkDocument = gql`
    query mixUserByPk($id: uuid!) {
  mix_user_by_pk(id: $id) {
    ...mix_user
  }
}
    ${Mix_UserFragmentDoc}`;
export type MixUserByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MixUserByPkQuery, MixUserByPkQueryVariables>, 'query'> & ({ variables: MixUserByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MixUserByPkComponent = (props: MixUserByPkComponentProps) => (
      <ApolloReactComponents.Query<MixUserByPkQuery, MixUserByPkQueryVariables> query={MixUserByPkDocument} {...props} />
    );
    

/**
 * __useMixUserByPkQuery__
 *
 * To run a query within a React component, call `useMixUserByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixUserByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixUserByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMixUserByPkQuery(baseOptions: Apollo.QueryHookOptions<MixUserByPkQuery, MixUserByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MixUserByPkQuery, MixUserByPkQueryVariables>(MixUserByPkDocument, options);
      }
export function useMixUserByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixUserByPkQuery, MixUserByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MixUserByPkQuery, MixUserByPkQueryVariables>(MixUserByPkDocument, options);
        }
export type MixUserByPkQueryHookResult = ReturnType<typeof useMixUserByPkQuery>;
export type MixUserByPkLazyQueryHookResult = ReturnType<typeof useMixUserByPkLazyQuery>;
export type MixUserByPkQueryResult = Apollo.QueryResult<MixUserByPkQuery, MixUserByPkQueryVariables>;
export const MixUserByRefreshTokenDocument = gql`
    query mixUserByRefreshToken($refreshToken: String!) {
  mix_user(where: {refreshToken: {_eq: $refreshToken}}) {
    id
    refreshTokenExpiry
  }
}
    `;
export type MixUserByRefreshTokenComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MixUserByRefreshTokenQuery, MixUserByRefreshTokenQueryVariables>, 'query'> & ({ variables: MixUserByRefreshTokenQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MixUserByRefreshTokenComponent = (props: MixUserByRefreshTokenComponentProps) => (
      <ApolloReactComponents.Query<MixUserByRefreshTokenQuery, MixUserByRefreshTokenQueryVariables> query={MixUserByRefreshTokenDocument} {...props} />
    );
    

/**
 * __useMixUserByRefreshTokenQuery__
 *
 * To run a query within a React component, call `useMixUserByRefreshTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixUserByRefreshTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixUserByRefreshTokenQuery({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useMixUserByRefreshTokenQuery(baseOptions: Apollo.QueryHookOptions<MixUserByRefreshTokenQuery, MixUserByRefreshTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MixUserByRefreshTokenQuery, MixUserByRefreshTokenQueryVariables>(MixUserByRefreshTokenDocument, options);
      }
export function useMixUserByRefreshTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixUserByRefreshTokenQuery, MixUserByRefreshTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MixUserByRefreshTokenQuery, MixUserByRefreshTokenQueryVariables>(MixUserByRefreshTokenDocument, options);
        }
export type MixUserByRefreshTokenQueryHookResult = ReturnType<typeof useMixUserByRefreshTokenQuery>;
export type MixUserByRefreshTokenLazyQueryHookResult = ReturnType<typeof useMixUserByRefreshTokenLazyQuery>;
export type MixUserByRefreshTokenQueryResult = Apollo.QueryResult<MixUserByRefreshTokenQuery, MixUserByRefreshTokenQueryVariables>;
export const OrderDocument = gql`
    query order($where: order_bool_exp) {
  order(where: $where, order_by: {created_at: desc}) {
    ...order
  }
}
    ${OrderFragmentDoc}`;
export type OrderComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrderQuery, OrderQueryVariables>, 'query'>;

    export const OrderComponent = (props: OrderComponentProps) => (
      <ApolloReactComponents.Query<OrderQuery, OrderQueryVariables> query={OrderDocument} {...props} />
    );
    

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrderQuery(baseOptions?: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrderByPkDocument = gql`
    query orderByPk($id: String!) {
  order_by_pk(id: $id) {
    ...order
  }
}
    ${OrderFragmentDoc}`;
export type OrderByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrderByPkQuery, OrderByPkQueryVariables>, 'query'> & ({ variables: OrderByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrderByPkComponent = (props: OrderByPkComponentProps) => (
      <ApolloReactComponents.Query<OrderByPkQuery, OrderByPkQueryVariables> query={OrderByPkDocument} {...props} />
    );
    

/**
 * __useOrderByPkQuery__
 *
 * To run a query within a React component, call `useOrderByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderByPkQuery(baseOptions: Apollo.QueryHookOptions<OrderByPkQuery, OrderByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderByPkQuery, OrderByPkQueryVariables>(OrderByPkDocument, options);
      }
export function useOrderByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderByPkQuery, OrderByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderByPkQuery, OrderByPkQueryVariables>(OrderByPkDocument, options);
        }
export type OrderByPkQueryHookResult = ReturnType<typeof useOrderByPkQuery>;
export type OrderByPkLazyQueryHookResult = ReturnType<typeof useOrderByPkLazyQuery>;
export type OrderByPkQueryResult = Apollo.QueryResult<OrderByPkQuery, OrderByPkQueryVariables>;
export const OriginalContentEncoreDocument = gql`
    query originalContentEncore($contentid: uuid!, $userid: uuid!) {
  mix_content_encore(
    where: {contentid: {_eq: $contentid}, userid: {_eq: $userid}}
  ) {
    id
    contentid
    userid
    count
  }
  mix_content_encore_aggregate(where: {contentid: {_eq: $contentid}}) {
    aggregate {
      sum {
        count
      }
    }
  }
}
    `;
export type OriginalContentEncoreComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OriginalContentEncoreQuery, OriginalContentEncoreQueryVariables>, 'query'> & ({ variables: OriginalContentEncoreQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OriginalContentEncoreComponent = (props: OriginalContentEncoreComponentProps) => (
      <ApolloReactComponents.Query<OriginalContentEncoreQuery, OriginalContentEncoreQueryVariables> query={OriginalContentEncoreDocument} {...props} />
    );
    

/**
 * __useOriginalContentEncoreQuery__
 *
 * To run a query within a React component, call `useOriginalContentEncoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useOriginalContentEncoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOriginalContentEncoreQuery({
 *   variables: {
 *      contentid: // value for 'contentid'
 *      userid: // value for 'userid'
 *   },
 * });
 */
export function useOriginalContentEncoreQuery(baseOptions: Apollo.QueryHookOptions<OriginalContentEncoreQuery, OriginalContentEncoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OriginalContentEncoreQuery, OriginalContentEncoreQueryVariables>(OriginalContentEncoreDocument, options);
      }
export function useOriginalContentEncoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OriginalContentEncoreQuery, OriginalContentEncoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OriginalContentEncoreQuery, OriginalContentEncoreQueryVariables>(OriginalContentEncoreDocument, options);
        }
export type OriginalContentEncoreQueryHookResult = ReturnType<typeof useOriginalContentEncoreQuery>;
export type OriginalContentEncoreLazyQueryHookResult = ReturnType<typeof useOriginalContentEncoreLazyQuery>;
export type OriginalContentEncoreQueryResult = Apollo.QueryResult<OriginalContentEncoreQuery, OriginalContentEncoreQueryVariables>;
export const ProductDocument = gql`
    query product($where: product_bool_exp) {
  product(where: $where, order_by: {created_at: desc}) {
    ...product
  }
}
    ${ProductFragmentDoc}`;
export type ProductComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductQuery, ProductQueryVariables>, 'query'>;

    export const ProductComponent = (props: ProductComponentProps) => (
      <ApolloReactComponents.Query<ProductQuery, ProductQueryVariables> query={ProductDocument} {...props} />
    );
    

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProductQuery(baseOptions?: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductByPkDocument = gql`
    query productByPk($id: uuid!) {
  product_by_pk(id: $id) {
    ...product
  }
}
    ${ProductFragmentDoc}`;
export type ProductByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductByPkQuery, ProductByPkQueryVariables>, 'query'> & ({ variables: ProductByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProductByPkComponent = (props: ProductByPkComponentProps) => (
      <ApolloReactComponents.Query<ProductByPkQuery, ProductByPkQueryVariables> query={ProductByPkDocument} {...props} />
    );
    

/**
 * __useProductByPkQuery__
 *
 * To run a query within a React component, call `useProductByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductByPkQuery(baseOptions: Apollo.QueryHookOptions<ProductByPkQuery, ProductByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductByPkQuery, ProductByPkQueryVariables>(ProductByPkDocument, options);
      }
export function useProductByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductByPkQuery, ProductByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductByPkQuery, ProductByPkQueryVariables>(ProductByPkDocument, options);
        }
export type ProductByPkQueryHookResult = ReturnType<typeof useProductByPkQuery>;
export type ProductByPkLazyQueryHookResult = ReturnType<typeof useProductByPkLazyQuery>;
export type ProductByPkQueryResult = Apollo.QueryResult<ProductByPkQuery, ProductByPkQueryVariables>;
export const SearchDocument = gql`
    query search($text: String!, $limit: Int, $offset: Int) {
  mix_content(
    where: {status: {_eq: published}, _or: [{authorname: {_ilike: $text}}, {title: {_ilike: $text}}, {abstractcontents: {_ilike: $text}}]}
    limit: $limit
    offset: $offset
    order_by: {updatetime: desc}
  ) {
    ...mix_content
  }
}
    ${Mix_ContentFragmentDoc}`;
export type SearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchQuery, SearchQueryVariables>, 'query'> & ({ variables: SearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchComponent = (props: SearchComponentProps) => (
      <ApolloReactComponents.Query<SearchQuery, SearchQueryVariables> query={SearchDocument} {...props} />
    );
    

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      text: // value for 'text'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const SessionDocument = gql`
    query session {
  session {
    id
    userId
    me {
      ...mix_user
    }
  }
}
    ${Mix_UserFragmentDoc}`;
export type SessionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SessionQuery, SessionQueryVariables>, 'query'>;

    export const SessionComponent = (props: SessionComponentProps) => (
      <ApolloReactComponents.Query<SessionQuery, SessionQueryVariables> query={SessionDocument} {...props} />
    );
    

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionQuery(baseOptions?: Apollo.QueryHookOptions<SessionQuery, SessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
      }
export function useSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionQuery, SessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
        }
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionQueryResult = Apollo.QueryResult<SessionQuery, SessionQueryVariables>;
export const TossPaymentsOrgCodeDocument = gql`
    query tossPaymentsOrgCode {
  toss_payments_org_code {
    id
    category
    organization
    code_num
    code_eng
    code_kor
    toss_update_date
  }
}
    `;
export type TossPaymentsOrgCodeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TossPaymentsOrgCodeQuery, TossPaymentsOrgCodeQueryVariables>, 'query'>;

    export const TossPaymentsOrgCodeComponent = (props: TossPaymentsOrgCodeComponentProps) => (
      <ApolloReactComponents.Query<TossPaymentsOrgCodeQuery, TossPaymentsOrgCodeQueryVariables> query={TossPaymentsOrgCodeDocument} {...props} />
    );
    

/**
 * __useTossPaymentsOrgCodeQuery__
 *
 * To run a query within a React component, call `useTossPaymentsOrgCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTossPaymentsOrgCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTossPaymentsOrgCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useTossPaymentsOrgCodeQuery(baseOptions?: Apollo.QueryHookOptions<TossPaymentsOrgCodeQuery, TossPaymentsOrgCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TossPaymentsOrgCodeQuery, TossPaymentsOrgCodeQueryVariables>(TossPaymentsOrgCodeDocument, options);
      }
export function useTossPaymentsOrgCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TossPaymentsOrgCodeQuery, TossPaymentsOrgCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TossPaymentsOrgCodeQuery, TossPaymentsOrgCodeQueryVariables>(TossPaymentsOrgCodeDocument, options);
        }
export type TossPaymentsOrgCodeQueryHookResult = ReturnType<typeof useTossPaymentsOrgCodeQuery>;
export type TossPaymentsOrgCodeLazyQueryHookResult = ReturnType<typeof useTossPaymentsOrgCodeLazyQuery>;
export type TossPaymentsOrgCodeQueryResult = Apollo.QueryResult<TossPaymentsOrgCodeQuery, TossPaymentsOrgCodeQueryVariables>;
export const UpdateMeDocument = gql`
    mutation updateMe($_set: mix_user_set_input!, $id: uuid!) {
  update_mix_user_by_pk(_set: $_set, pk_columns: {id: $id}) {
    ...mix_user
  }
}
    ${Mix_UserFragmentDoc}`;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;
export type UpdateMeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMeMutation, UpdateMeMutationVariables>, 'mutation'>;

    export const UpdateMeComponent = (props: UpdateMeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMeMutation, UpdateMeMutationVariables> mutation={UpdateMeDocument} {...props} />
    );
    

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const UpdateOrderByPkDocument = gql`
    mutation updateOrderByPk($_set: order_set_input!, $id: String!) {
  update_order_by_pk(_set: $_set, pk_columns: {id: $id}) {
    ...order
  }
}
    ${OrderFragmentDoc}`;
export type UpdateOrderByPkMutationFn = Apollo.MutationFunction<UpdateOrderByPkMutation, UpdateOrderByPkMutationVariables>;
export type UpdateOrderByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrderByPkMutation, UpdateOrderByPkMutationVariables>, 'mutation'>;

    export const UpdateOrderByPkComponent = (props: UpdateOrderByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrderByPkMutation, UpdateOrderByPkMutationVariables> mutation={UpdateOrderByPkDocument} {...props} />
    );
    

/**
 * __useUpdateOrderByPkMutation__
 *
 * To run a mutation, you first call `useUpdateOrderByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderByPkMutation, { data, loading, error }] = useUpdateOrderByPkMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrderByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderByPkMutation, UpdateOrderByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderByPkMutation, UpdateOrderByPkMutationVariables>(UpdateOrderByPkDocument, options);
      }
export type UpdateOrderByPkMutationHookResult = ReturnType<typeof useUpdateOrderByPkMutation>;
export type UpdateOrderByPkMutationResult = Apollo.MutationResult<UpdateOrderByPkMutation>;
export type UpdateOrderByPkMutationOptions = Apollo.BaseMutationOptions<UpdateOrderByPkMutation, UpdateOrderByPkMutationVariables>;
export const UpsertContentHistoryDocument = gql`
    mutation upsertContentHistory($contentId: uuid!) {
  insert_mix_content_history_one(
    object: {contentid: $contentId}
    on_conflict: {constraint: mix_content_history_userid_contentid_key, update_columns: [updatetime]}
  ) {
    id
  }
}
    `;
export type UpsertContentHistoryMutationFn = Apollo.MutationFunction<UpsertContentHistoryMutation, UpsertContentHistoryMutationVariables>;
export type UpsertContentHistoryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertContentHistoryMutation, UpsertContentHistoryMutationVariables>, 'mutation'>;

    export const UpsertContentHistoryComponent = (props: UpsertContentHistoryComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertContentHistoryMutation, UpsertContentHistoryMutationVariables> mutation={UpsertContentHistoryDocument} {...props} />
    );
    

/**
 * __useUpsertContentHistoryMutation__
 *
 * To run a mutation, you first call `useUpsertContentHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContentHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContentHistoryMutation, { data, loading, error }] = useUpsertContentHistoryMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useUpsertContentHistoryMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContentHistoryMutation, UpsertContentHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContentHistoryMutation, UpsertContentHistoryMutationVariables>(UpsertContentHistoryDocument, options);
      }
export type UpsertContentHistoryMutationHookResult = ReturnType<typeof useUpsertContentHistoryMutation>;
export type UpsertContentHistoryMutationResult = Apollo.MutationResult<UpsertContentHistoryMutation>;
export type UpsertContentHistoryMutationOptions = Apollo.BaseMutationOptions<UpsertContentHistoryMutation, UpsertContentHistoryMutationVariables>;
export const UpsertMixContentEncoreDocument = gql`
    mutation upsertMixContentEncore($contentid: uuid!, $count: Int!) {
  insert_mix_content_encore_one(
    object: {contentid: $contentid, count: $count}
    on_conflict: {constraint: mix_content_encore_contentid_userid_key, update_columns: [count]}
  ) {
    id
    contentid
    userid
    count
  }
}
    `;
export type UpsertMixContentEncoreMutationFn = Apollo.MutationFunction<UpsertMixContentEncoreMutation, UpsertMixContentEncoreMutationVariables>;
export type UpsertMixContentEncoreComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertMixContentEncoreMutation, UpsertMixContentEncoreMutationVariables>, 'mutation'>;

    export const UpsertMixContentEncoreComponent = (props: UpsertMixContentEncoreComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertMixContentEncoreMutation, UpsertMixContentEncoreMutationVariables> mutation={UpsertMixContentEncoreDocument} {...props} />
    );
    

/**
 * __useUpsertMixContentEncoreMutation__
 *
 * To run a mutation, you first call `useUpsertMixContentEncoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMixContentEncoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMixContentEncoreMutation, { data, loading, error }] = useUpsertMixContentEncoreMutation({
 *   variables: {
 *      contentid: // value for 'contentid'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useUpsertMixContentEncoreMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMixContentEncoreMutation, UpsertMixContentEncoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMixContentEncoreMutation, UpsertMixContentEncoreMutationVariables>(UpsertMixContentEncoreDocument, options);
      }
export type UpsertMixContentEncoreMutationHookResult = ReturnType<typeof useUpsertMixContentEncoreMutation>;
export type UpsertMixContentEncoreMutationResult = Apollo.MutationResult<UpsertMixContentEncoreMutation>;
export type UpsertMixContentEncoreMutationOptions = Apollo.BaseMutationOptions<UpsertMixContentEncoreMutation, UpsertMixContentEncoreMutationVariables>;
export const UpsertOfflineAttendeeDocument = gql`
    mutation upsertOfflineAttendee($objects: [offline_attendee_insert_input!]!) {
  insert_offline_attendee(
    objects: $objects
    on_conflict: {constraint: offline_attendee_order_id_phone_key, update_columns: [email, name, company, position, career, advance_question, team_and_role, interest_category, is_vip_networking_enrolled]}
  ) {
    returning {
      id
      order_id
    }
  }
}
    `;
export type UpsertOfflineAttendeeMutationFn = Apollo.MutationFunction<UpsertOfflineAttendeeMutation, UpsertOfflineAttendeeMutationVariables>;
export type UpsertOfflineAttendeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertOfflineAttendeeMutation, UpsertOfflineAttendeeMutationVariables>, 'mutation'>;

    export const UpsertOfflineAttendeeComponent = (props: UpsertOfflineAttendeeComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertOfflineAttendeeMutation, UpsertOfflineAttendeeMutationVariables> mutation={UpsertOfflineAttendeeDocument} {...props} />
    );
    

/**
 * __useUpsertOfflineAttendeeMutation__
 *
 * To run a mutation, you first call `useUpsertOfflineAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOfflineAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOfflineAttendeeMutation, { data, loading, error }] = useUpsertOfflineAttendeeMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertOfflineAttendeeMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOfflineAttendeeMutation, UpsertOfflineAttendeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertOfflineAttendeeMutation, UpsertOfflineAttendeeMutationVariables>(UpsertOfflineAttendeeDocument, options);
      }
export type UpsertOfflineAttendeeMutationHookResult = ReturnType<typeof useUpsertOfflineAttendeeMutation>;
export type UpsertOfflineAttendeeMutationResult = Apollo.MutationResult<UpsertOfflineAttendeeMutation>;
export type UpsertOfflineAttendeeMutationOptions = Apollo.BaseMutationOptions<UpsertOfflineAttendeeMutation, UpsertOfflineAttendeeMutationVariables>;
export const UpsertOriginalContentsDocument = gql`
    mutation upsertOriginalContents($objects: [mix_content_insert_input!]!) {
  insert_mix_content(
    objects: $objects
    on_conflict: {constraint: mix_content_source_contentid_key, update_columns: [abstractcontents, author, authorname, imgurl, originalurl, readtime, redirecturl, title, updatetime]}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpsertOriginalContentsMutationFn = Apollo.MutationFunction<UpsertOriginalContentsMutation, UpsertOriginalContentsMutationVariables>;
export type UpsertOriginalContentsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertOriginalContentsMutation, UpsertOriginalContentsMutationVariables>, 'mutation'>;

    export const UpsertOriginalContentsComponent = (props: UpsertOriginalContentsComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertOriginalContentsMutation, UpsertOriginalContentsMutationVariables> mutation={UpsertOriginalContentsDocument} {...props} />
    );
    

/**
 * __useUpsertOriginalContentsMutation__
 *
 * To run a mutation, you first call `useUpsertOriginalContentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOriginalContentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOriginalContentsMutation, { data, loading, error }] = useUpsertOriginalContentsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertOriginalContentsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOriginalContentsMutation, UpsertOriginalContentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertOriginalContentsMutation, UpsertOriginalContentsMutationVariables>(UpsertOriginalContentsDocument, options);
      }
export type UpsertOriginalContentsMutationHookResult = ReturnType<typeof useUpsertOriginalContentsMutation>;
export type UpsertOriginalContentsMutationResult = Apollo.MutationResult<UpsertOriginalContentsMutation>;
export type UpsertOriginalContentsMutationOptions = Apollo.BaseMutationOptions<UpsertOriginalContentsMutation, UpsertOriginalContentsMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    